import {HttpClient} from '@angular/common/http';
import {Injectable, makeStateKey, StateKey, TransferState} from '@angular/core';
import {Observable} from 'rxjs';

import {FEATURE_TOGGLE_STATE_KEY, FeatureToggleLoaderService} from '../../models/feature-toggle';

@Injectable()
export class FeatureToggleBrowserLoaderService extends FeatureToggleLoaderService {

  constructor(
    private readonly http: HttpClient,
    private readonly transferState: TransferState,
  ) {
    super();
  }

  getConfig(): Observable<any> {
    const key: StateKey<string> = makeStateKey<string>(FEATURE_TOGGLE_STATE_KEY);
    const data: any = this.transferState.get(key, null);

    return new Observable((observer) => {
      if (data) {
        observer.next(data.features);
        observer.complete();
      }
      import('../../../../../../assets/config.json').then(res => {
        observer.next(res.features);
        observer.complete();
      });
    });
  }

}
